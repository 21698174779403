import { slideUp, slideDown } from '../js/utilities/slide.js'

export function initHighlightEditors() {
    document.addEventListener('click', (evt) => {
        const bannerButton = evt.target as HTMLDivElement
        if (bannerButton.matches('.bannerPoint__button')) {
            const bannerPoint = bannerButton.closest<HTMLDivElement>('.bannerPoint');
            const bannerPointContent = next<HTMLDivElement>(bannerButton, '.bannerPoint__content')

            if (bannerPoint.classList.contains('active')) {
                slideUp(bannerPointContent, 100, "linear", () => {
                    bannerPointContent.style.left = ''
                })
                bannerPoint.classList.remove('active')
            } else {
                hideAllBannerPoints(() => {
                    setPositionOfBannerPointContent(bannerPointContent);
                    slideDown(bannerPointContent)
                    bannerPoint.classList.add('active')
                })
            }
        } else {
            hideAllBannerPoints()
        }
    })
}

function hideAllBannerPoints(successFn: Function = () => {}) {
    document.querySelectorAll<HTMLDivElement>('.bannerPoints').forEach(bannerPoints => {
        bannerPoints.querySelectorAll<HTMLDivElement>('.bannerPoint').forEach(bannerPoint => {
            bannerPoint.classList.remove('active')
            bannerPoint.style.display = '';
        })
        bannerPoints.querySelectorAll<HTMLDivElement>('.bannerPoint__content').forEach(bannerPointContent => {
            slideUp(bannerPointContent, 1, "linear", () => {
                bannerPointContent.style.left = ''
                bannerPointContent.style.right = ''
                bannerPointContent.style.textAlign = ''
                successFn()
            })
        })
    })
}


function setPositionOfBannerPointContent(bannerContent: HTMLDivElement) {
    bannerContent.style.display = ''
    bannerContent.style.visibility = 'hidden'
    const widthContent = bannerContent.offsetWidth;
    const offsetContent = offset(bannerContent)
    bannerContent.style.left = ''
    bannerContent.style.right = ''
    bannerContent.style.textAlign = ''

    if (offsetContent.left + widthContent > window.innerWidth) {
        const leftPositionOfContent = window.innerWidth - offsetContent.left - widthContent;
        bannerContent.style.left = (leftPositionOfContent - 10).toString()
    }
    if (offsetContent.left < 0) {
        const rightPositionOfContent = offsetContent.left;
        bannerContent.style.right = (rightPositionOfContent - 10).toString()
        bannerContent.style.textAlign = 'left'
    }

    bannerContent.style.visibility = ''
}

function next<T>(el: HTMLElement, selector?: string): T | null {
    const nextEl = el.nextElementSibling as T;
    if (!selector || (nextEl && nextEl.matches(selector))) {
        return nextEl;
    }
    return null;
}

function offset(el: HTMLElement) {
    const box = el.getBoundingClientRect();
    const docElem = document.documentElement;
    return {
        top: box.top + window.scrollY - docElem.clientTop,
        left: box.left + window.scrollX - docElem.clientLeft
    };
}